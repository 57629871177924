const ar = {
  auctionLots: "جميع القِطع",
  auctionTimepieces: "الساعات",
  auctionHandbags: "حقائب اليد",
  auctionCars: "السيارات",
  auctionLotlh: "رقم القِطعة (من الأدنى إلى الأعلى)",
  auctionLothl: "رقم القِطعة (من الأعلى إلى الأدنى)",
  auctionReslh: "الحد الأدنى للسعر (من الأدنى إلى الأعلى)",
  auctionReshl: "الحد الأدنى للسعر (من الأعلى إلى الأدنى)",
  auctionEstimate: "السعر التقديري",
  auctionReserve: "الحد الأدنى للسعر",
  auctionLot: "القطعة",
  auctionTitle: "اكتشفوا المزيد",
  carTips: "عرض المنتج غير مسموح بالمزاد",
};
const en = {
  auctionLots: "All Lots",
  auctionTimepieces: "Timepieces",
  auctionHandbags: "Handbags",
  auctionCars: "Automobiles",
  auctionLotlh: "Lot Number (low to high)",
  auctionLothl: "Lot Number (high to low)",
  auctionReslh: "Reserve (low to high)",
  auctionReshl: "Reserve (high to low)",
  auctionEstimate: "ESTIMATE",
  auctionReserve: "RESERVE",
  auctionLot: "LOT",
  auctionTitle: "Discover more lots",
  carTips: "Product make an offer not allowed for auction",
};

export { ar, en };
